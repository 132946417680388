.footer-site-map-logo-menu-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footer-site-map-address-text-container {
  padding-bottom: 30px;
}

.footer-nav-inner .nav-link p,
.footer-accordion p {
  padding-top: 0px;
  padding-bottom: 0px;
}

.footer-accordion {
  padding-top: 8px;
  padding-bottom: 0px;
}


/* contact icons and text */
.footer-site-map-contact-info-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footer-site-map-title-container h4 {
  text-align: center;
}

.footer-site-map-contact-info-container p {
  margin: 0;
}

.footer-site-map-p-container p {
  text-align: center;
}


.footer-site-map-contact-info-containe {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.footer-menu-info-container {
  display: flex;
  flex-direction: column;
  row-gap: 30px !important;
  padding-bottom: 30px;
}

.footer-social-media-container {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
}

@media (min-width: 476px) {
  .footer-site-map-contact-info-container {
    justify-content: flex-start;
  }

  .footer-site-map-title-container h4 {
    text-align: left;
  }

  .footer-site-map-p-container p {
    text-align: left;
  }

  .footer-menu-info-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 5%;
  }

  .footer-social-media-container {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px;
  }

  .footer-site-map-container {
    display: inline-flex;
    flex-direction: column;
  }

  .footer-site-map-logo-menu-container {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    /* gap: 20%; */
  }

  .footer-site-map-logo-container {
    padding-right: 20px;
  }

  .footer-address-menu-container {
    display: inline-block;
    padding-bottom: 0px;
    padding-left: 50px;
  }
}

@media (min-width: 650px) {
  /* .footer-working-hours-menu-container {
    padding-left: 100px;
  } */
}

@media (min-width: 676px) {
  .footer-address-menu-container {
    padding-right: 0%;
  }
}

@media (min-width: 1000px) {
  .footer-inner-container {
    /* padding-top: 30px;
    padding-bottom: 30px; */
    max-width: 1200px !important;
  }

  .footer-menu-info-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 10%;
  }

  .footer-working-hours-menu-container {
    padding-right: 0;
  }

  .footer-contact-menu-container {
    margin-top: 0%;
  }
}
