@font-face {
    font-family: 'Helvetica';
    src: url('./font/helveticaneueltstd_lt-webfont.woff2') format('woff2'),
       url('./font/helveticaneueltstd_lt-webfont.woff') format('woff'),
       url('./font/HelveticaNeueLTStd\ Lt.otf') format('truetype');
       font-weight: normal;
       font-style: normal;
}

@font-face {
    font-family: 'Helvetica Caps';
    src: url('./font//HelveticaNeueLTGEOW82-45Lt-Regular.ttf') format('truetype');
}