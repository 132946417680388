:root {
  --background-color-while-image-loading: rgb(96, 96, 96);
  --partners-boxes-width: 100px;
  --body-mine-background: #e9ebeb;
  --body-background: linear-gradient(to right, #dadada, #ffffff);
  --button-background-yellow: #ffcc04 !important;
  --button-text-yellow: #4a4a4a !important;
  --text-primary: grey;
  --text-secondary: light;
  --mine-yellow: yellow;
  --navbar-background: grey;
  --link-background: white;
  --link-text: orangered;
  --navbar-dropdown-svg-color: yellow;
  --offcanvas-body-background-color: rgb(250, 250, 250);
  --offcanvas-header-background: rgb(255, 255, 255);
  --offcanvas-link-text: rgb(0, 0, 0);
  --offcanvas-title-text: black;
  --contact-us-button: #ffcc04;
  --flags-backrgound-color: rgba(252, 252, 252, 0.137);
  --border-yellow: #ffcc04 !important;
  --partners-background-color: rgb(231, 229, 216);
  --partners-card-background-color: rgba(128, 128, 128, 0);
  --projects-card-p: rgb(255, 255, 255);
  --projects-cards-title-color: rgb(255, 255, 255);
  --fa-chevron-arrow-color: black;
  --projects-card-body-background-color: rgb(33, 37, 41);
  --projects-card-body-background-color-less-opacity: rgba(33, 37, 41, 0.526);
  --quete-comments-background-color: rgba(33, 37, 41);
  --quete-comments-text-color: rgb(255, 255, 255);
  --comments-title-background-color: #ffc107;
  --comments-title-text-color: #000000;
  --quete-author-text-color: rgb(255, 255, 255);
  --instagram-icon-color: #e1306c !important;
  --facebook-linkdin-icon-color: #4267b2 !important;
  --linkdin-icon-color: rgba(0, 119, 181) !important;
  --footer-navbar-text-color: #ffcc04;
  --footer-navbar-text-hover-color: rgb(244, 244, 204);
  --footer-background-color: rgba(33, 37, 41);
  --footer-text-color: rgb(236, 224, 224);
  --footer-social-media-icons-background-color: #989898b1;
  --contact-page-form-border-color: rgb(184, 184, 184);
  /* calculator active button */
  --bs-btn-active-bg: #131005;

  /* padding variables */
  --app-padding-sm-left-right: 16px !important;
  --app-padding-md-left-right: 2px !important;
  --navbar-md-padding-left-right: 40px !important;
  --navbar-lg-padding-left-right: 50px !important;
  --navbar-xxl-padding-left-right: 70px !important;

  /* override color */
  --bs-body-bg: transparent !important;
  --bs-accordion-btn-icon-color: var(--footer-navbar-text-color) !important;

  background-image: var(--body-background);

  /* pink */
  --pink: #ff7e5f;

  /* error colors */
  --error-border: rgb(255, 137, 137);
  --error-backgrould-color: rgb(246, 216, 216);

  /* success collor */
  --success-color: rgb(35, 152, 35);
}

a {
  cursor: pointer;
}

.footer-nav-inner .nav-link {
  cursor: pointer;
  position: relative;
  text-align: center;
}

.footer-nav-inner .nav-link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0%;
  width: 0%;
  height: 100%;
  transition: all 0.5s;
}

.footer-nav-inner .nav-link:hover::before {
  background-color: #d6d0d020;
  left: 0;
  width: 100%;
}

/* .footer a:hover {

  color: var(--footer-navbar-text-color);
  cursor: pointer;
  background-color: #dfdbdb1c;
} */

.footer a:visited {
  transform: scale(1.01);
  color: var(--footer-navbar-text-color);
  cursor: pointer;
}

html {
  scroll-behavior: smooth;
}

body {
  box-sizing: border-box !important;
  margin: 0;
}

body {
  font-size: 16px;
  line-height: 1.5;
}

p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-family: Helvetica, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "helvetica Caps";
}

h1 {
  font-size: 36px;
  line-height: 1.2;
}

h2 {
  font-size: 32px;
  line-height: 1.3;
}

h3 {
  font-size: 28px;
  line-height: 1.3;
}

h4 {
  font-size: 24px;
  line-height: 1.4;
}

h5 {
  font-size: 20px;
  line-height: 1.5;
}

h6 {
  font-size: 18px;
  line-height: 1.5;
}

p {
  font-size: 16px;
  line-height: 1.75;
}

.helvetica-caps {
  font-family: "Helvetica Caps" !important;
}

blockquote {
  font-size: 18px;
  line-height: 1.75;
}

.caption,
.label {
  font-size: 14px;
  line-height: 1.5;
}

nav a {
  font-size: 16px;
  line-height: 1.6;
}

button {
  font-size: 16px;
  line-height: 1.6;
}

.App,
.footer {
  width: 100% !important;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.App-blur {
  filter: blur(2px) !important;
}

#calculator,
#projects,
#comment {
  padding-left: var(--app-padding-sm-left-right);
  padding-right: var(--app-padding-sm-left-right);
}

.projects {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.footer {
  padding-top: 30px;
  padding-bottom: 30px;
  color: var(--footer-text-color);
}

.row {
  width: 100% !important;
  padding-left: 0% !important;
  padding-right: 0% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

p {
  padding-left: 0px !important;
  padding-right: 0px !important;
  /* text-align: justify; */
  /* word-break: break-all; */
  hyphens: auto;
  word-wrap: break-word;
}

h2 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

/* navbar */
.navbar-container {
  height: 60px;
}

.offcanvas-full-navbar {
  display: flex;
  justify-content: flex-end;
  gap: 1px;
}

.navbar-drop-down-1400px .dropdown-menu {
  background-color: var(--offcanvas-body-background-color);
}

.contact-us-button {
  position: relative;
  background-color: var(--contact-us-button) !important;
  font-weight: 600 !important;
  font-family: "Helvetica Caps";
  /* z-index: 10; */
}

.contact-menu {
  position: relative;
  display: flex;
}

.contact-button-after {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 140%;
  top: -20%;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  transition: all 0.5s;
}

.contact-menu:hover {
  height: calc(100% - 4px);
  background-color: #000000 !important;
}

.contact-menu:hover .contact-button-after {
  opacity: 1;
  z-index: -1;
  height: calc(100% - 4px);
  top: 2px;
  border-color: var(--contact-us-button);
}

/* .contact-us-button::before {
  height: 100% !important;
  position: relative;
  width: 100% !important;
  border: 5px solid black !important;
  background-color: #000000 !important;
  z-index: 1000 !;
} */

.logo-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.lang-toggle-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.close-open-menu {
  height: 22px;
  width: 22px;

  padding: 0;
  margin: 0;
  position: relative;
}

.close-open-menu span:first-child {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.offcanvas-menu {
  width: 300px !important;
  max-width: 80% !important;
  /* background-color: rgba(255, 255, 255, 0.408) !important; */
}

.offcanvas-title {
  position: relative;
  display: flex;
  align-items: center;
}

.offcanvas-contact {
  cursor: pointer;
  z-index: 10;
}

.offcanvas-title-after {
  position: absolute;
  opacity: 0;
  width: 75%;
  height: 50px;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-top: 2px solid var(--button-background-yellow);
  border-bottom: 2px solid var(--button-background-yellow);
  transition: all 0.5s;
}

.offcanvas-title:hover {
  /* height: calc(100% - 4px); */
  /* background-color: #000000 !important; */
}

.offcanvas-title:hover .offcanvas-title-after {
  width: 75%;
  opacity: 1;
  z-index: 1;
  height: 30px;
  top: 0px;
  border-color: var(--contact-us-button);
}

.offcanvas-body-background-color {
  background-color: var(--offcanvas-body-background-color);
}

.offcanvas-header-background-color {
  height: 60px !important;
  background-color: var(--offcanvas-header-background) !important;
  border-bottom: 1px solid rgba(128, 128, 128, 0.258);
}

.offcanvas-full-navbar a {
  color: var(--offcanvas-link-text) !important;
  font-family: "Helvetica Caps" !important;
}

.offcanvas-contact {
  background-color: var(--button-background-yellow) !important;
  color: var(--offcanvas-title-text) !important;
  font-family: "Helvetica Caps";
}

.dropdown-menu {
  border-radius: 0 !important;
  border-left: none !important;
  border-right: none !important;
}

.menu-drop-down-item-container {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start;
}

.menu-drop-down-item-container svg {
  width: 20px;
  color: var(--navbar-dropdown-svg-color);
}

.menu-drop-down-item-container p {
  margin-bottom: 0;
  padding-left: 10px;
}

.navbar-dropdown-icon {
  padding-right: 10px;
  color: var(--quete-comments-background-color) !important;
}

.usa-flag {
  margin-bottom: 2px;
  margin-top: 2px;
  border-radius: 6px;
}

.dropdown-toggle,
.nav-link {
  padding-left: 5px !important;
}

.offcanvas-links-container a {
  padding-left: 20px !important;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase !important;
}

.offcanvas-body .nav-link {
  position: relative;
  z-index: 1;
}

.offcanvas-body .nav-link::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%; /* Start with 0 width */
  background: #f4e3854d;
  transition: width 0.3s ease; /* Smooth transition */
  z-index: -1;
  transition: all 0.4s;
}

.offcanvas-body .nav-link:hover::before {
  width: 100% !important;
  /* background-color: #a6a5a53c; */
  left: 0%;
  /* z-index: 10; */
}

.dropdown-toggle {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px !important;
}

.dropdown-item:active {
  background-color: var(--offcanvas-body-background-color) !important;
}

.flags-drop-down-item .dropdown-menu {
  background-color: var(--offcanvas-body-background-color) !important;
  --bs-dropdown-min-width: 40px !important;
  --bs-dropdown-divider-margin-y: 0px;
  padding-bottom: 0px !important;
  opacity: 0;
  transform: translateY(-10px);
}
.flags-drop-down-item .dropdown-menu.show {
  opacity: 1;
  transform: translateY(0);
}

.fadein .dropdown-menu {
  opacity: 1;
  animation: fadeIn 0.3s ease forwards;
}

.fadeout .dropdown-menu:not(.show) {
  display: block;
  animation: fadeOut 0.3s ease forwards;
}

.dropdown-menu {
  border: none !important;
  width: 20px !important;
}

.p-relative {
  position: relative;
  width: 100%;
  height: 100%;
}

.p-absolute-center {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

#mainpage1 {
  margin-top: 60px;
}

.video-page-inner-container {
  /* height: 60vh !important; */
  width: 100%;
  aspect-ratio: 7 / 4;
}

.video-container {
  position: relative;
  width: 100%;
  /* height: 60vh !important; */
  aspect-ratio: 7 / 4;
  overflow: hidden;
}

.video-container video {
  /* height: 100% !important; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: 7 / 4;
  object-fit: cover;
  pointer-events: none;
  z-index: -11; /* Place behind other content */
}

.black-pattern {
  opacity: 55%;
}

.video-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* transform: translateY(70px); */
}

.video-link {
  position: relative;
  border: 2px solid white;
  /* animation-name: videoTextAnimation1;
  animation-duration: 5s;
  animation-iteration-count: infinite; */
}

.video-link {
  /* overflow: hidden; */
  position: relative;
}

.video-link::after {
  content: "";
  position: absolute;
  opacity: 0;
  height: 100%;
  width: 115%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-left: 2px solid rgb(254, 254, 254);
  border-right: 2px solid rgb(255, 255, 255);
  transition: all 0.6s;
}

.video-link::before {
  content: "";
  position: absolute;
  opacity: 0;
  height: 150%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-top: 2px solid rgb(254, 254, 254);
  border-bottom: 2px solid rgb(255, 255, 255);
  transition: all 0.6s;
}

.video-link:hover::before,
.video-link:hover::after {
  opacity: 1;
  height: 100%;
  width: 100%;
}

/* .video-link::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%; 
  background: #f4e3854d;
  transition: width 0.3s ease;
  z-index: -1;
  transition: all 0.4s;
}

.video-link:hover::before {
  width: 100% !important;
  left: 0%;
} */

/* .video-link::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 2px;
  top: 0;
  left: 0;
  border-radius: 10px;
  background-color: var(--contact-us-button);
  animation-name: videoTextAnimation3;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
} */

.video-link::before {
}

/* .video-link:hover {
  border-width: 5px;
  border-radius: 10px;
} */

#stats {
  margin-top: -1px !important;
}

.stats-container {
  width: 100%;
  padding-left: 0px !important;
  padding-right: 0px !important;
  display: flex;
  justify-content: center;
}

.stats-row-contaier {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 30px;
}

.stats-icons-text-container {
  display: inline-flex;
  justify-content: center;
}

.stats-inner-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}

.stats-inner-container img {
  height: calc(100vh / 3 - 25vh);
  object-fit: contain;
}

.stats-inner-container p {
  margin-bottom: 0px;
}

.stats-text-container p {
  font-family: "Helvetica Caps";
}

/* calculator styles */

.calc-count-price-container {
  position: fixed;
  display: none;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 998;
  width: 100%;
  height: 100%;
  /* background-color: rgba(255, 255, 255, 0.496); */
}

.calc-count-price-inner-container {
  background-color: white;
  width: 80%;
  max-width: 900px;
  z-index: 1000;
}

.blur-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(2px);
  z-index: 999;
}

/* .calc-count-price-inner-container div {
} */

.request-price-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.request-price-form .request-email,
.request-price-form .request-phoneNumber {
  background-color: var(--contact-us-button) !important;
  border-radius: 0;
  width: 80%;
}

.request-email,
.request-phoneNumber,
.request-submit-button {
  height: 60px !important;
}

#request-email-input,
#request-phoneNumber-input {
  border-radius: 0 !important;
}

.request-submit-button {
  background-color: var(--contact-us-button) !important;
  border-radius: 0 !important;
  border: none !important;
  color: black !important;
  width: 50%;
}

.calculator-page-container {
  padding-left: 50px !important;
  padding-right: 50px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid rgb(33, 37, 41); */
  background-color: var(--partners-background-color);
  border-radius: 20px;
}

.calculator-page-container h2 {
  font-family: "Helvetica Caps";
}

.calculator-input {
  border: none !important;
  border: 1px solid var(--border-yellow) !important;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background-color: var(--button-background-yellow);
  border-color: var(--button-background-yellow);
}

.btn-group > :not(.btn-check:first-child) + .btn {
  margin-left: 0px !important;
}

.calculator-person-company-button {
  font-family: "Helvetica Caps" !important;
}

.calculator-submit-button {
  background-color: var(--button-background-yellow) !important;
  color: var(--button-text-yellow) !important;
}

.calculator-hr {
  width: 100%;
}

.calcComponent-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.regions-panels-col {
  border: 1px solid rgb(192, 192, 192);
  margin-top: 20px !important;
}

.regions-panels-form {
  padding: 10px 0px 10px 0px;
}

.regions-panels-title-container {
  padding-bottom: 10px;
}

.panel-location-radio-container {
  padding: 6px 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
  border: 1px solid rgba(128, 128, 128, 0.155);
  border-radius: 10px;
}

.form-check-input {
  border-color: #9f9f9f !important;
  margin-top: 6px !important;
}

.calc-check-field {
  border-radius: 0px !important;
}

.calculator-error-class {
  background-color: rgb(245, 124, 124);
  border: 1px solid red !important;
}

.calc-input-title {
  position: relative;
  transition: all 0.4s;
  text-align: center;
}

.calc-title-error-text {
  color: #d32020;
  transition: color 0.4s;
}

.calc-input-title-container {
  overflow: hidden;
}

.calc-input-title::after {
  position: absolute;
  content: "*";
  width: 20px;
  height: 20px;
  font-size: 30px;
  line-height: 10px;
  transform: translateY(300%);
  transition: all 0.4s;
  opacity: 0;
}

.calc-title-error-text::after {
  transform: translateY(30%);
  color: red;
  opacity: 1;
}

.calc-title-error-text::before {
  content: "";
  position: absolute;
  left: -100%;
  height: 100%;
  width: 10%;
  background-color: #f5dede83;
  display: inline;
  animation: calcErortextBackgroundAnimation;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}

.calc-title-error-text::before {
}

.calc-title-error-text {
  /* background-color: #000000; */
}

#partners {
  background-color: var(--partners-background-color);
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  width: 100%;
}

.partners-container {
  max-width: 90%;
  padding-left: 22px !important;
  padding-right: 22px !important;
}

.partners-row-1 {
  width: 100%;
}

.partners-col-container {
  width: 10% !important;
}

.card-img,
.partners-card svg {
  filter: sepia(100%) grayscale(100%);
  transition: transform 0.5s ease-in-out;
}

.card-img-4 {
  filter: grayscale(100%) invert(30%);
}

.card-img-5 {
  filter: grayscale(100%) invert(40%);
}
.card-img-6 {
  filter: grayscale(100%) invert(30%);
}

.card-img-8 {
  filter: grayscale(60%) invert(30%);
}

.card-img-14 {
  filter: grayscale(100%) invert(50%);
}

.card-img-20 {
  filter: grayscale(100%) invert(50%);
}

.card-img:hover,
.partners-card svg:hover {
  filter: sepia(0%) brightness(1) grayscale(0) invert(0%) !important;
}

.partners-card {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--partners-card-background-color);
  overflow: hidden;
}

.card-link-container {
  background-color: rgba(128, 128, 128, 0);
  position: absolute;
  padding: 0;
  cursor: default !important;
  display: inline-flex;
}

.partners-col-container,
.partners-card,
.partners-card-link a,
.card-inner-body,
.card-link-container {
  cursor: default !important;
}

.card-inner-body {
  width: 100%;
  height: 100%;

  background-color: rgba(245, 239, 239, 0) !important;
}

.partners-card-link {
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  transform: translateY(200%);
}
.partners-card .card-img,
.partners-card svg {
  cursor: pointer;
}

.card-body:hover .card-link {
  transform: translateY(0);
}

.card-img-top {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-img-1 {
  padding-left: 0px;
  transform: scale(0.9);
}

.partners-col-container-2 {
  width: 15% !important;
}

.card-img-2 {
  transform: scale(0.9);
}

.card-img-3 {
  transform: scale(0.9);
}

.card-img-4 {
  margin-top: -10px;
  transform: scale(0.7);
}

.card-img-5 {
  transform: scale(0.7);
}

.card-img-6 {
  transform: scale(0.8);
}

.partners-col-container-7 {
  width: 14% !important;
}

.card-img-7 {
  padding-right: 10px;
  transform: scale(0.8);
}

.card-img-8 {
  transform: scale(1);
  padding-left: 10px;
}

.card-img-9 {
  transform: scale(1.1);
}

.card-10 {
  height: 50% !important;
}

.card-img-10 {
  transform: scale(1);
}

.card-11 {
  transform: scale(1);
}

.card-img-12 {
  transform: scale(1);
}

.card-img-13 {
  transform: scale(0.8);
}
.card-img-14 {
  transform: scale(0.6);
}

.partners-col-container-15 {
  width: 14% !important;
}

.card-img-15 {
  padding-left: 10px !important;
  transform: scale(1);
}

.card-img-16 {
  transform: scale(0.7);
}

.card-img-17 {
  transform: scale(0.7);
}

.card-img-18 {
  transform: scale(1.1);
}

.card-img-19 {
  transform: scale(0.75);
}

.partners-col-container-20 {
  width: 12% !important;
  padding-right: 15px;
}

.card-img-20 {
  padding-right: 15px !important;
  transform: scale(0.9);
}

.partners-col-container {
  width: 17% !important;
}

.project-img {
  aspect-ratio: 1 / 1;
  width: 100%;
  object-fit: cover;
}

.item-inner-container {
  box-shadow: 0px 2px 8px #888888;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.item-inner-container:hover {
  box-shadow: 0px 1px 10px 1px #76898e;
}

.projects-carousel-link::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  transition: all 0.5s;
}

.projects-carousel-link:hover::before {
  background-color: #ede8572b;
  height: 100%;
}

.item-inner-container:hover .projects-body-container {
  background-color: var(--projects-card-body-background-color);
  transition: background-color 0.5s;
}
.item-inner-container:not(:hover) .projects-body-container {
  background-color: var(--projects-card-body-background-color-less-opacity);
  transition: background-color 0.5s;
}

.item-inner-container:hover .projects-slide-mwh-p {
  background-color: var(--projects-card-body-background-color);
  transition: background-color 0.7s;
}

.item-inner-container:not(:hover) .projects-slide-mwh-p {
  background-color: var(--projects-card-body-background-color-less-opacity);
  transition: background-color 0.3s;
}

.projects-carousel-link {
  position: relative;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projects-body-container {
  position: absolute;
  /* height: 33%; */
  /* min-height: ; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* background-color: #0000003e; */
  padding: 10px;
}

.projects-text {
  /* flex: 1; */
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  overflow: hidden;
  color: var(--projects-card-p);

  /* display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; */
  /* height: 50px !important; */
  overflow: hidden;
  /* text-overflow: ellipsis; */
  white-space: normal;
  text-align: center;
}

.projects-slide-mwh {
  position: absolute;
  left: 0;
  top: 0;
  padding-left: 10px;
  padding-top: 10px;
}

.projects-slide-mwh-p {
  color: rgb(255, 255, 255);
  text-align: left;
  background-color: var(--projects-card-body-background-color-less-opacity);
  border-radius: 10px;
  padding: 5px 10px !important;
}

a {
  text-decoration: none !important;
}

.projects-body-container {
  display: flex;
}

.projects-title {
  text-decoration: none !important;
  color: var(--projects-cards-title-color);
  margin-right: auto;
  /* margin-top: auto; */
  margin-bottom: 0px;
  padding: 0px;
  text-align: left;
  word-break: keep-all;
  word-wrap: break-word;
  border-radius: 10px;
}

.projects-location {
  text-decoration: none !important;
  color: var(--projects-cards-title-color);
  margin-right: auto;
  margin-bottom: 0px;
  /* margin-top: auto;
  margin-bottom: 0px; */
  padding: 0px;
  text-align: left;
  word-break: keep-all;
  word-wrap: break-word;
  font-size: 12px;
  border-radius: 10px;
}

.slick-list,
.slick-track {
  height: 100%;
}

.slick-list {
  width: 90%;
}

.slick-slider {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.slick-slider {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding-bottom: 25px;
}

.slick-slide div:not(.employ-img-container, .employ-name-linkdin-container) {
  /* height: 100% !important; */
}

.slick-arrow {
  width: 20px !important;
  height: 50px !important;
  z-index: 1000;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.slick-prev {
  left: 0 !important;
}

.slick-next {
  right: 0 !important;
}

.slick-arrow::before {
  display: none;
}

.fa-chevron-right,
.fa-chevron-left {
  width: 100%;
  height: 100%;
  color: var(--fa-chevron-arrow-color);
}

.slick-dots {
  bottom: 0 !important;
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.bottom--25 {
  padding-bottom: 25px !important;
}

.bottom--50 {
  padding-bottom: 50px !important;
}

.bottom--75 {
  padding-bottom: 75px !important;
}

.item-container {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.projects-body-container {
  /* background-color: var(--projects-card-body-background-color-less-opacity); */
  bottom: 0;
}

.projects-body-container {
  background-color: var(--projects-card-body-background-color-less-opacity);
}

/* comments style */

.comments-component {
  padding-left: 0px !important;
  padding-right: 0px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.comments-main-title {
  margin-bottom: 0px;
}

.comments-title-container h5 {
  color: var(--comments-title-text-color);
}

.comments-item-inner-container {
  box-shadow: none;
}

.comments-component-row .slick-slider {
  display: flex;
  justify-content: center;
  align-items: center;
}

.comments-multiitem-carousel-text-container h6 {
  color: var(--quete-comments-text-color);
}

.font-italic {
  font-style: italic;
}

.comments-item-inner-container {
  background-color: var(--quete-comments-background-color);
  color: var(--quete-comments-text-color);
  box-shadow: 3px 2px 5px rgb(157, 157, 157);
  border-radius: 0px;
  padding: 10px 20px !important;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.comment-item-container {
  text-align: center;
}

.comment-p-container {
  overflow: hidden;
  margin-bottom: 10px;
}

.comment-p-container p {
  width: 100%;
  font-weight: 300;
  word-break: break-all;
  text-align-last: center;
  color: var(--quete-comments-text-color);
}

.comment-item-container {
  text-align: center;
  font-weight: bold;
}

.comment-company-name h6 {
  font-style: italic;
}

/* footer styles */
.footer-menu-info-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.footer-icon-inner-container {
  width: 50px;
  height: 50px;
  padding: 0 !important;
  background-color: var(--footer-social-media-icons-background-color);
}

.footer-icon-inner-container a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-icon-inner-container:nth-child(1) a:hover {
  /* transition: color 0.1s linear 0.1s; */
  /* color: var(--facebook-linkdin-icon-color) !important; */
}

.footer-icon-inner-container:nth-child(2) a:hover {
  /* transition: color 0.1s linear 0.1s; */
  /* color: var(--instagram-icon-color) !important; */
}

.footer-icon-inner-container:nth-child(3) a:hover {
  /* transition: color 0.1s linear 0.1s; */
  /* color: var(--linkdin-icon-color) !important; */
  transform: scale(1);
  /* transition: all 0.5s; */
}

.footer-icon-inner-container:hover {
  box-shadow: 2px 2px 1px var(--footer-social-media-icons-background-color);
  transform: scale(1.2);
}

.footer h4 {
  font-size: 22px;
}

.footer p {
  font-size: 14px;
}

.footer-map-inner-container {
  display: inline-flex;
  width: 100%;
}

.footer-logo-container {
  display: inline-flex;
  width: 30%;
}

.footer-text-container,
.footer-map-inner-container {
  display: flex;
  justify-content: center;
}

.footer-text-col {
  display: flex;
  justify-content: flex-start;
}

.footer-text-inner-container {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  text-align: left;
}

.footer-text-inner-container p,
.footer-text-inner-container h4 {
  display: flex;
  margin: 0;
}

.footer-text-inner-container p {
  font-size: small;
}

.footer-info-containers {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
}

.site-map-hr {
  height: 1px;
}

.footer-accordion-container {
  display: inline-flex;
  width: 100%;
  --bs-accordion-bg: none !important;
  --bs-accordion-active-bg: none !important;
}

.accordion-item {
  width: 100%;
}

.footer-accordion button,
.footer-nav-inner a {
  padding-left: 0 !important;
  padding-right: 0;
  padding-bottom: 0px !important;
  color: var(--footer-navbar-text-color);
}

.footer-accordion button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0.1rem;
  box-shadow: none !important;
}

.footer-accordion button::after {
  background-image: url("./images/accordionButton/arrowIcon.svg");
  fill: red !important;
}

.footer-accordion button::after .footer-nav-inner a {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.footer-nav-inner a {
  font-family: "Helvetica Caps";
}

.accordion-header {
  padding-top: 10px !important;
}

.footer-nav-inner a {
  padding: 10px !important;
}

.footer-about-us {
  padding-top: 8px !important;
}

.footer-large-scale {
  padding-top: 10px !important;
}

.footer-text-col,
.footer-map-inner-container {
  justify-content: center;
}

.footer-map-inner-container {
  display: inline-flex;
  flex-direction: column;
}

.footer-logo-menu-container {
  display: inline-flex;
  justify-content: space-between;
}

.footer-nav-inner {
  display: inline-flex;
  flex-direction: column;
}

.footer-text-col {
  justify-content: flex-start;
  padding-left: 0 !important;
}

.one-line-link {
  white-space: nowrap; /* Prevent line break */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Show ellipsis (...) for overflow */
}

footer p {
  font-size: small !important;
}

.footer {
  background-color: var(--footer-background-color);
}

.footer-menu-info-container a {
  color: var(--footer-navbar-text-color);
}

.footer-icon-inner-container a {
  color: white !important;
}

.footer-icon-inner-container a:hover {
  color: var(--footer-navbar-text-color) !important;
}

.footer-nav-inner a {
  cursor: default;
}

.footer-menu-info-container a p {
  cursor: pointer !important;
}

.footer-menu-info-container p {
  padding: 8px 0px;
}

.footer-nav-inner .nav-link:nth-child(1) p {
  padding-top: 0px;
}

.footer-menu-info-container a p,
.footer-accordion button p,
.footer.accordion button,
.footer-menu-info-container a p:visited,
.footer-accordion button p:visited {
  margin-bottom: 0;
  color: var(--footer-navbar-text-color);
}

.footer-menu-info-container a p:hover,
.footer-accordion button:hover {
  background-color: #00000009;

  margin-bottom: 0;
}

.footer-site-map-p-container p {
  margin-bottom: 0px;
}

.footer-large-scale {
  padding-top: 10px !important;
}

.footer-text-col {
  justify-content: center !important;
}

.footer-text-container {
  justify-content: space-between;
}

.footer-working-hours-container {
  justify-content: flex-start !important;
  padding-left: 50px !important;
}

.footer-contact-container {
  justify-content: flex-end !important;
}

/* about us page */
.about-us-container {
  margin-top: 60px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-us-img-text-container {
  padding-top: 30px;
  padding-bottom: 30px;
}

.about-us-ohmenergy-title {
  margin-right: auto;
  padding-left: 30px;
  font-size: 50px;
  font-weight: 700;
}

.about-us-text-container {
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  /* height: 100%; */
}

.about-us-our-vision-h5 {
  font-size: 50px;
}

.about-us-text-container h5,
.about-us-text-container p {
  padding-left: 30px !important;
}

.about-us-text-container p {
  /* text-align: center; */
  word-break: keep-all;
  font-size: 18px;
  /* padding-left: 30px !important; */
  /* margin-top: auto; */
  /* margin-bottom: auto; */
}

.about-us-row-3 {
  padding-left: var(--app-padding-sm-left-right) !important;
  padding-right: var(--app-padding-sm-left-right) !important;
  /* padding-top: 30px; */
}

.about-us-title {
  padding-bottom: 30px;
  /* padding-top: 10px; */
}

.about-us-row-1,
.about-us-row-2,
.about-us-row-3 {
  max-width: 1200px;
}

.about-us-row-1 {
  /* padding-bottom: 0px; */
}

.about-us-ohm-energu-span {
  font-weight: bold;
  font-size: 20px;
}

.about-us-img-1-container {
  width: 100%;
}

.about-us-img-1-inner-container,
.about-us-img-2-inner-container {
  width: 100%;
  max-height: 70vh;
}

.about-us-img-1,
.about-us-img-2 {
  width: 100%;
  height: 100%;
  max-height: 70vh;
  object-fit: cover;
}

.employ-img-container {
  border: 1px solid rgb(211, 211, 211);
  padding: 20px;
}

.employ-img {
  width: 100%;
  /* padding: 5px; */
  object-fit: contain;
}

.employ-name-linkdin-container {
  width: 100%;
  padding-top: 12px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.employ-name-position-container {
  width: 80%;
}

.employ-name-position-container h6 {
  width: 100%;
  font-size: 18px;
  font-weight: 600;
}

.employ-name-position-container p {
  width: 100%;
  font-size: 12px;
  margin-bottom: 0px;
}

.employ-linkdin {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.employ-linkdin a,
.employ-linkdin a svg {
  width: 100%;
  height: 100%;
}

.employ-linkdin a svg {
  color: var(--linkdin-icon-color);
}

/* partnersPage style */
.partners-page-container {
  max-width: 1200px;
  margin-top: 60px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.partners-page-title {
  margin-bottom: 30px;
}

.partners-page-row {
  border: 1px solid grey;
  margin-top: 20px !important;
}

.partners-page-partner-name-inner {
  cursor: pointer;
}

.partners-page-row:hover {
  border-color: transparent;
  box-shadow: 0px 1px 20px 0px rgb(202, 202, 202);
  transition: box-shadow 0.2s;
}

.partners-page-col {
  padding: 10px;
  /* padding-right: 0px !important; */
}

.partners-page-text-col {
  padding: 25px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.partners-page-title-container h5 {
  margin-bottom: 12px;
}

.partners-page-paragraph-container p {
  margin-bottom: 20px;
}

.partners-page-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 10px;
}

.partners-page-img {
  width: 100%;
  height: 100%;
  max-height: 170px;
}

.partners-page-img {
  object-fit: contain;
  height: 100px;
}

.partners-page-img-inner-container svg {
  width: 100%;
  height: 100%;
}

.partners-page-img-2 {
  transform: scale(1.3);
}

.partners-page-img {
  object-fit: contain;
}

.partners-page-img-inner-container {
  width: 50%;
  height: 100px;
}

.partners-page-flag-name-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.partners-page-partner-name {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.partners-page-partner-name-inner {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.partners-page-partner-name-inner:visited,
.partners-page-partner-name-inner:active,
.partners-page-partner-name-inner:hover,
.partners-page-partner-name-inner:visited h5,
.partners-page-partner-name-inner:active h5,
.partners-page-partner-name-inner:hover h5 {
  color: var(--comments-title-text-color);
}

.partners-page-row {
  overflow: hidden;
}

.partners-page-partner-name h5 {
  margin-bottom: 0px;
}

.partners-page-flag-container {
  height: 100%;
  width: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.partners-page-flag-container img {
  width: 32px;
  height: 24px;
  object-fit: cover;
  border-radius: 3px;
  box-shadow: 0px 0px 7px 1px grey;
}

/* Projects Page style */
.projects-page-container {
  max-width: 1200px;
  margin-top: 60px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.projects-page-title {
  padding-bottom: 20px;
}

.projects-page-title h5 {
  margin-bottom: 0px;
  text-align: center;
}

.projects-page-toggle-button {
  background-color: #ffffff !important;
  border: 1px solid #ffc107 !important;
  border-radius: 10px !important;
  font-family: "Helvetica Caps" !important;
  font-weight: 600 !important;
}

.projects-page-toggle-button-selected {
  background-color: #ffc107 !important;
  box-shadow: 1px 5px 10px rgb(183, 183, 183);
}

.projects-page-component-row {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 1px;
  row-gap: 2px;
  padding-top: 30px;
}

.projects-page-col {
  padding: 0px !important;
  width: 100%;
  overflow: hidden;
}

.projects-page-projects-inner-container::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(234, 255, 0, 0.101);
  transition: 0.5s;
  /* z-index: 2; */
}

.projects-page-col:hover .projects-page-projects-inner-container::before {
  top: 0;
}

.projects-page-col:hover .projects-page-location-container {
  background-color: var(--projects-card-body-background-color);
  transition: background-color 0.3s;
}

.projects-page-col:not(:hover) .projects-page-location-container {
  background-color: var(--projects-card-body-background-color-less-opacity);
  transition: background-color 1s;
}

.projects-page-col:hover .projects-page-project-kw {
  background-color: var(--projects-card-body-background-color);
  transition: background-color 0.7s;
}

.projects-page-col:not(:hover) .projects-page-project-kw {
  background-color: var(--projects-card-body-background-color-less-opacity);
  transition: background-color 0.2s;
}

.projects-page-col-inner-container {
  position: relative;
}

.projects-page-image-container {
  width: 100%;
  /* max-height: 70vh; */
}

.projects-page-image {
  width: 100%;
  aspect-ratio: 1 / 1;
  background-color: #ffc107;
  object-fit: cover;
  filter: sepia(0.3);
}

.projects-page-image-filter-div {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #0730ff21;
  filter: sepia(0.4) brightness(2) hue-rotate(0.7) invert(0.4);
}

.projects-page-project-text-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.projects-page-projects-inner-container {
  padding: 0px;
  background-color: #0a080120;
}

.projects-page-project-kw {
  font-weight: bold;
  background-color: #0a080164;
  padding: 10px;
  border-radius: 10px;
  margin: 20px;
  z-index: 2;
}

.projects-page-projects-inner-container p {
  margin-bottom: 0px;
}

.projects-page-projects-inner-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  color: white;
}

.projects-page-location-container {
  width: 100%;
  padding: 20px;
  background-color: var(--projects-card-body-background-color-less-opacity);
  z-index: 2;
}

.projects-page-project-title {
  word-break: keep-all;
}

.single-project-container {
  margin-top: 60px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.single-page-row {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 30px;
}

.single-page-row {
  max-width: 1200px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.single-page-row-1-inner-container {
  position: relative;
  width: 100%;
  min-height: 600px;
  max-height: 40vh;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.single-page-main-image-container {
  height: 60%;
  padding-left: 0px !important;
  padding-right: 0px !important;
  overflow: hidden !important;
  position: relative;
}

.single-page-main-image-layer {
  width: 100%;
  height: 100%;
  background-color: #868f082d;
  position: absolute;
  top: 0;
  left: 0;
  filter: sepia(0.4) brightness(2) hue-rotate(0.7) invert(0.4);
  z-index: 10;
}

.single-page-main-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.single-page-row-1-name-table-container {
  /* height: 60% !important; */
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1000;
}

.single-page-row-1-name-table-inner-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.single-page-row-1-table-container {
  padding-left: var(--app-padding-sm-left-right);
  padding-right: var(--app-padding-sm-left-right);
  z-index: 10;
  bottom: 0;
}

.table-container {
  background-color: #e9ebeb;
}

.table {
  background-color: #fefefe;
}

.table-row {
  display: flex;
  border: 1px solid rgba(128, 128, 128, 0.374);
  border-top: none;
}

.table-row-1 {
  border-top: 1px solid rgba(128, 128, 128, 0.374);
}

.thead {
  width: 120px !important;
  display: flex;
  align-items: center;
}

.thead h6 {
  width: 120px !important;
  height: 100%;
  border-right: 1px solid rgba(128, 128, 128, 0.244);
  margin: 0px;
  display: flex;
  align-items: center;
  word-break: break-all;
}

.tbody {
  display: flex;
  align-items: center;
  width: 70%;
}

.single-page-row-1-name-container {
  padding-bottom: 30px;
}

.single-page-row-1-name-container h2 {
  color: white;
  text-align: center;
}

.single-page-rows-container {
  max-width: 1200px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.single-page-video-container {
  width: 100%;
}

.single-page-video-container iframe {
  width: 100%;
  aspect-ratio: 9 / 5;
}

.single-page-description-container {
  display: inline-flex;
  flex-direction: column;
  padding-bottom: 30px;
}

.single-page-description-container {
  display: inline-flex;
  flex-direction: column;
}

.set-width-100 {
  padding-bottom: 0px;
}

.single-page-row-3-col {
  margin-bottom: 10px;
}

.single-page-row-3-col img {
  width: 100%;
  object-fit: contain;
  max-height: calc(100vh - 80px);
}

.single-page-carousel-row {
  padding-bottom: 40px !important;
}

/* contact page style */

.contact-page-container {
  margin-top: 60px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.contact-page-forms-inner-container form {
  max-width: 500px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
}

.contact-page-form {
  padding: 20px;
}

.contact-page-form-name-company-name-input-container,
.contact-page-form-email-phone-number-input-container,
.contact-page-from-comment-input {
  width: 100%;
}

/* .contact-page-from-name-input,
.contact-page-from-company-name-input,
.contact-page-from-email-input,
.contact-page-from-comment-input,
.contact-page-form {
  border: 1px solid var(--contact-page-form-border-color);
  border-radius: 7px;
} */

.show-email-tooltip {
  position: absolute;
  left: 0;
  top: 0;
  height: 20px;
  width: 100%;
  color: #e83030;
  text-align: center;
  z-index: 9999;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.contact-page-from-company-name-input {
  border: 1px solid var(--contact-page-form-border-color);
  border-radius: 10px !important;
}

.contact-page-from-comment-input {
  margin-bottom: 20px;
}

.contact-page-form-number-container {
  display: flex;
  /* border: 1px solid var(--contact-page-form-border-color);
  border-radius: 10px; */
  border-radius: 10px !important;
}

.contact-page-form-number-start-input-container {
  height: 100% !important;
  width: 10% !important;
  min-width: 120px !important;
  border-right: 1px solid rgba(0, 0, 0, 0.184);
}

.contact-page-form-phone-number-start {
  border: none !important;
  border-right: 1px solid var(--contact-page-form-border-color) !important;
  border-radius: 0px !important;
  border-radius: 10px !important;
}

.contact-page-form-phone-number-start {
  background-position: right 10px bottom 15px !important;
}

.contact-page-form-number-input {
  border: none !important;
}

.contact-page-form-number-input-container {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  width: 100% !important;
  position: relative;
}

.contact-page-form-number-container {
  position: relative;
  border-radius: 10px !important;
}

.show-phonenumber-tooltip,
.show-email-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 100%;
  margin-bottom: 0px;
  /* margin-left: 20px; */
  color: #524f4f;
  text-align: center;
  z-index: 2;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px; */
  /* border-radius: 10px; */
  opacity: 0;
  transition: all 1s;
}

.show-phonenumber-tooltip p,
.show-email-tooltip p {
  background-color: var(--error-backgrould-color);
  /* border-left: 1px solid var(--error-border);
  border-right: 1px solid var(--error-border); */
  margin-bottom: 0;
  padding-left: 10px !important;
  padding-right: 10px !important;
  height: 100%;
  display: inline-flex;
  align-items: center;
  box-shadow: 1px 5px 10px rgb(188, 173, 173);
}

.form-control {
  border: none !important;
}

.contact-page-submit-button {
  background-color: var(--button-background-yellow) !important;
  color: var(--offcanvas-title-text) !important;
  border: none !important;
}

.check-field {
  border: 1px solid var(--contact-page-form-border-color) !important;
  border-radius: 10px !important;
}

#email-input,
#name-input,
#company-name-input {
  border-radius: 10px !important;
}

#phone-number-input {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.contact-page-form-number-input {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.country-code-select {
  background-position: right 0.75rem bottom 0.8rem !important;
  border: none !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  background-color: transparent !important;
}

.success-class {
  border-color: var(--success-color) !important;
}

.error-class {
  background-color: var(--error-backgrould-color) !important;
  border-color: var(--error-border) !important;
  /* z-index: 10000 !important; */
}

.contact-page-row-title {
  padding-bottom: 30px;
}

.contact-page-row-title h4 {
  text-align: center;
}

.contact-page-row-info {
  padding-bottom: 30px;
  max-width: 1200px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.contact-page-adress-col {
  padding-bottom: 30px;
}

.contact-page-adress-inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 30px;
}

.location-icon {
  height: 50px;
  color: #919191;
}

.map-page-adress-working-hours {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-page-row-map {
  height: 50vh;
  width: 80% !important;
  max-width: 800px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.contact-page-map-col {
  min-height: 40vh;
}

.map-container {
  width: 100%;
  height: 100% !important;
}

.leaflet-popup-content {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}

.fa-location-dot {
  width: 100%;
  height: 100%;
  size: "2x";
}

.fa-location-dot::before {
  width: 100%;
  height: 100%;
  font-size: 60px;
  color: rgb(210, 29, 29);
}

.contact-page-submit-button {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media (min-width: 400px) {
  .employ-name-position-container h6 {
    font-size: 10px;
  }

  .employ-name-position-container p {
    font-size: 8px;
  }
}

@media (min-width: 420px) {
  .employ-img-container {
    padding: 10px;
  }

  .employ-name-linkdin-container {
    padding-top: 20px;
    padding-left: 10px;
  }
}

@media (min-width: 476px) {
  .footer-nav-inner .nav-link {
    text-align: left;
  }
}

@media (min-width: 490px) {
  .single-page-row-1-inner-container {
    min-height: 500px;
    max-height: 60vh;
  }
}

@media (min-width: 550px) {
  /* stats component style */

  .stats-row-contaier {
    flex-direction: row;
    justify-content: space-around;
    /* gap: 10%; */
  }

  .stats-inner-container img {
    height: 90px;
    object-fit: contain;
  }
}

@media (min-width: 576px) {
  /* partners page style */

  .partners-page-col {
    padding-right: 0px !important;
  }

  .partners-page-img-container {
    border-right: 1px solid grey;
  }

  .partners-page-img-inner-container {
    width: 100%;
    height: 100%;
  }

  .partners-page-img {
    object-fit: contain;
  }

  .partners-page-img {
    width: 100%;
    height: 100%;
    max-height: 170px;
  }

  .partners-page-img-0 {
    transform: scale(0.5);
  }

  .partners-page-img-1 {
    transform: scale(0.65);
  }

  .partners-page-img-2 {
    transform: scale(0.8);
    object-fit: contain;
  }

  .partners-page-img-3 {
    transform: scale(0.5);
  }

  .partners-page-img-4 {
    transform: scale(0.4);
  }

  .partners-page-img-5 {
    transform: scale(0.45);
  }

  .partners-page-img-6 {
    transform: scale(0.5);
  }

  .partners-page-img-7 {
    transform: scale(0.45);
  }
  .partners-page-img-8 {
    transform: scale(0.5);
  }
  .partners-page-img-9 {
    transform: scale(0.7);
  }
  .partners-page-img-10 {
    transform: scale(0.45);
  }
  .partners-page-img-11 {
    transform: scale(0.5);
  }
  .partners-page-img-12 {
    transform: scale(0.45);
  }
  .partners-page-img-13 {
    transform: scale(0.45);
  }
  .partners-page-img-14 {
    transform: scale(0.5);
  }
  .partners-page-img-15 {
    transform: scale(0.57);
  }
  .partners-page-img-16 {
    transform: scale(0.5);
  }
  .partners-page-img-17 {
    transform: scale(0.65);
  }
  .partners-page-img-18 {
    transform: scale(0.55);
  }
  .partners-page-img-19 {
    transform: scale(0.5);
  }

  /* projects page style */
  .projects-page-component-row {
    justify-content: space-between;
    row-gap: 3px;
  }

  .projects-page-toggle-button {
    margin-left: 10px;
    margin-right: 10px;
  }

  .projects-page-col {
    width: calc(50% - 1px) !important;
  }

  /* .projects-page-col div:nth-child(1) h4 {
    word-break: break-all;
  } */

  /* single projects page style */
  .thead {
    width: 30% !important;
    display: flex;
    align-items: center;
  }

  .thead h6 {
    width: 100% !important;
    height: 100%;
    border-right: 1px solid rgba(128, 128, 128, 0.244);
    margin: 0px;
    display: flex;
    align-items: center;
  }

  /* contact page style */

  .contact-page-form-name-company-name-input-container {
    display: flex;
    justify-content: space-between;
  }

  .contact-page-form-name-company-name-input-container
    .contact-page-from-input {
    width: 45% !important;
  }
}

@media (min-width: 600px) {
  .employ-name-position-container h6 {
    font-size: 10px;
  }

  .employ-name-position-container p {
    font-size: 8px;
  }

  /* video dimensions */
  .video-page-inner-container,
  .video-container,
  .video-container video {
    aspect-ratio: 2 / 1;
  }
}

@media (min-width: 665px) {
  .panel-location-radio-container,
  .region-select-container {
    height: 39px !important;
  }

  .panel-location-radio-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: 0px;
  }
}

@media (min-width: 690px) {
  .employ-name-position-container h6 {
    font-size: 12px;
  }

  .employ-name-position-container p {
    font-size: 9px;
  }

  /* video dimensions */
  .video-page-inner-container,
  .video-container,
  .video-container video {
    aspect-ratio: 7 / 3;
  }
}

@media (min-width: 768px) {
  /* video dimensions */
  .video-page-inner-container,
  .video-container,
  .video-container video {
    width: 100%;
    height: 70vh;
  }

  .stats-text-container {
    width: 100% !important;
  }

  .stats-text-container p {
    text-align: center;
    margin-bottom: 0px;
  }

  /* calct components */

  .calcComponent-container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }

  .calculator-person-company-button {
    width: 50%;
  }

  .calc-input-title {
    text-align: left;
  }

  .request-price-button {
    margin-top: auto !important;
    width: 200px !important;
    height: 50px;
  }

  .footer-text-col {
    justify-content: center;
  }

  .footer-logo-container {
    width: 50%;
  }

  .working-hours {
    text-wrap: none;
    flex-shrink: 0;
    line-break: none;
  }

  .employ-name-position-container h6 {
    font-size: 14px;
  }

  .employ-name-position-container p {
    font-size: 11px;
  }

  /* single project page style */

  .single-page-row-1-name-container {
    padding-bottom: 30px;
    padding-left: var(--app-padding-sm-left-right);
  }

  .single-page-row-1-name-container h2 {
    width: 50%;
    text-align: left;
  }

  .thead {
    width: 50% !important;
  }

  .tbody {
    width: 50% !important;
  }

  .thead h6 {
    width: 100% !important;
  }

  .single-page-row-2 {
    flex-direction: row-reverse;
  }

  .single-page-description-container {
    /* width: 50% !important; */
    padding-bottom: 0px;
  }

  .set-width-50 {
    width: 50% !important;
  }
  .set-width-100 {
    width: 100% !important;
    padding-bottom: 0px;
  }

  .single-page-row-3 {
    display: flex;
    gap: 5px !important;
  }

  .single-page-row-3-col {
    width: calc(50% - 2.5px) !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin: 0px;
  }

  /* contact page style */
  .contact-page-form-email-phone-number-input-container {
    display: flex;
    justify-content: space-between;
  }

  .contact-page-form-email-phone-number-input-container
    .form-floating-container,
  .contact-page-from-email-input {
    width: 45% !important;
  }

  .contact-page-forms-inner-container form {
    max-width: 750px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .projects-page-image {
    aspect-ratio: 3 / 2;
  }
}

@media (min-width: 992px) {
  /* video dimensions */
  .video-page-inner-container,
  .video-container,
  .video-container video {
    aspect-ratio: 9 / 3;
  }

  /* single project page style */
  .single-page-row-1-inner-container {
    min-height: 670px;
    max-height: 60vh;
  }

  .single-page-row-1-name-container {
    padding-bottom: 100px;
  }

  .footer {
    padding-left: var(--app-padding-md-left-right);
    padding-right: var(--app-padding-md-left-right);
  }

  .App {
    max-width: 100%;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  #calculator,
  #projects,
  #comment {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  .navbar-container {
    width: 100% !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: auto;
    margin-right: auto;
  }

  .navbar-inner-container {
    padding-left: var(--navbar-md-padding-left-right) !important;
    padding-right: var(--navbar-md-padding-left-right) !important;
  }

  .menu-toggle-container {
    width: 100%;
    order: 0;
  }

  .offcanvas-full-navbar {
    width: 100%;
    gap: 11px;
  }

  #offcanvasNavbar-expand-sm {
    max-width: 100% !important;
  }

  .offcanvas-menu {
    width: 100% !important;
    margin-left: auto !important;
  }

  .offcanvas-links-container a {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }

  .offcanvas-body .nav-link::before {
    left: 0;
    top: 0%;
    height: 0;
    width: 0;
  }

  .offcanvas-body .nav-link:hover::before {
    height: 100%;
    width: 100%;
  }

  .contact-menu {
    width: 108px;
    order: 1;
  }

  .contact-menu a {
    width: 108px;
    order: 1;
    text-wrap: nowrap;
  }

  .lang-container {
    order: 2;
  }

  .lang-toggle-container {
    width: 84%;
    justify-content: flex-end;
    gap: 20px;
  }

  .offcanvas-menu {
    width: 70%;
  }

  .panels-location-container {
    margin-top: 20px;
  }

  .panel-location-radio-container {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .region-panel-row {
    display: flex;
    justify-content: space-between;
  }

  .regions-panels-col {
    border: none;
    margin: 0px !important;
    padding: 0px !important;
  }

  .select-region-col {
    padding-right: 4px !important;
  }

  .panels-col {
    padding-left: 4px !important;
  }

  .regions-panels-form {
    border: 1px solid rgb(192, 192, 192);
    margin-top: 20px !important;
    padding: 10px 0px 10px 0px;
  }

  .regions-panels-form {
    padding: 10px;
  }

  .footer-working-hours-container,
  .footer-contact-container {
    justify-content: center !important;
  }

  .footer-menu-info-container {
    padding-bottom: 30px;
  }

  .footer-social-media-container {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .flags-drop-down-item .nav-link {
    padding-right: 0px !important;
  }

  .projects-container .slick-slider .slick-list {
    padding-bottom: 20px !important;
  }

  .projects-container {
    /* padding-left: 20px !important; */
  }

  .projects-page-col:last-child {
    margin-right: auto;
    margin-left: 2px;
  }

  .stats-row-contaier {
    justify-content: space-evenly;
    max-width: 900px;
  }

  .about-us-img-text-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
  }

  .about-us-img-text-container-2 {
    flex-direction: row;
  }

  .about-us-text-container {
    width: 47% !important;
    display: flex;
    align-items: center;
  }

  .about-us-text-container {
    padding-bottom: 3px;
  }

  .about-us-img-1-container {
    width: 47% !important;
  }

  .employ-name-position-container h6 {
    font-size: 18px;
  }

  .employ-img-container {
    padding: 20px;
  }

  .employ-name-linkdin-container {
    padding-left: 20px;
  }

  .employ-name-position-container p {
    font-size: 14px;
  }

  /* projects page style */
  .projects-page-col {
    width: calc(100% / 3 - 2px) !important;
  }

  /* single page style */
  .single-page-main-image-container {
    height: 80%;
  }

  .single-page-row-1-name-table-inner-container {
    max-width: 1200px;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transform: translateX(-50%);
  }

  /* contact page style */

  .contact-page-forms-inner-container form {
    max-width: 900px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .projects-location {
    font-size: 13px;
  }

  /* partners page */
  .partners-page-flag-name-container {
    /* display: none; */
    /* opacity: 0; */
    transform: translateY(200%);
  }

  .partners-page-row:hover .partners-page-flag-name-container {
    transform: translateY(0%);
    transition: transform 0.3s linear;
  }

  .partners-page-row:not(:hover) .partners-page-flag-name-container {
    /* opacity: 0; */
    transform: translateY(220%);
    transition: transform 0.3s linear;
  }
}

@media (min-width: 1010px) {
  .select-region-col {
    padding-right: 10px !important;
  }

  .panels-col {
    padding-left: 10px !important;
  }

  /* partners logos style */
  .partners-col-container {
    width: 9% !important;
    /* padding-right: 15px; */
  }

  .card-img-1 {
    padding-left: -0px;
    transform: translateX(-10px) scale(0.7);
  }

  .partners-col-container-2 {
    width: 14% !important;
  }

  .card-img-2 {
    transform: scale(0.7);
  }

  .card-img-3 {
    transform: scale(0.6);
  }

  .card-img-4 {
    margin-top: -15px;
    transform: scale(0.45);
  }

  .card-img-5 {
    transform: scale(0.45);
  }

  .card-img-6 {
    transform: scale(0.55);
  }

  .card-img-7 {
    padding-right: 10px;
    transform: scale(0.6);
  }

  .card-img-8 {
    transform: scale(0.7);
    padding-left: 10px;
  }

  .card-img-9 {
    transform: scale(0.8);
  }

  .card-10 {
    height: 35% !important;
  }

  .card-img-10 {
    transform: scale(0.75);
  }

  .card-11 {
    transform: scale(0.7);
    margin-left: -14px !important;
  }

  .partners-col-container-12 {
    width: 12% !important;
  }

  .card-img-12 {
    transform: scale(0.6);
  }

  .card-img-13 {
    transform: scale(0.55);
  }
  .card-img-14 {
    transform: scale(0.4);
  }

  .partners-col-container-15 {
    width: 12% !important;
  }

  .card-img-15 {
    padding-left: 10px !important;
    transform: scale(0.75);
  }

  .card-img-16 {
    transform: scale(0.45);
  }

  .card-img-17 {
    transform: scale(0.45);
  }

  .card-img-18 {
    transform: scale(0.75);
  }

  .card-img-19 {
    transform: scale(0.45);
  }

  .card-img-20 {
    padding-right: 15px !important;
    transform: scale(0.75);
  }
}

@media (min-width: 1090px) {
  .offcanvas-full-navbar {
    gap: 25px;
  }

  .lang-toggle-container {
    gap: 25px;
  }
}

@media (min-width: 1190px) {
  .offcanvas-full-navbar {
    gap: 30px;
  }

  .offcanvas-links-container a {
    font-size: 18px;
  }

  .panel-location-radio-container {
    padding: 0px;
  }

  .lang-toggle-container {
    gap: 40px;
  }

  .card-img-1 {
    transform: translateX(-10%) scale(0.7);
    padding-left: 0px !important;
  }
}

@media (min-width: 1210px) {
  .offcanvas-full-navbar {
    gap: 35px;
  }

  .lang-toggle-container {
    gap: 35px;
  }
}

@media (min-width: 1250px) {
  .offcanvas-full-navbar {
    gap: 40px;
  }

  .lang-toggle-container {
    gap: 40px;
  }
}

@media (min-width: 2000px) {
  /* video dimensions */
  .video-page-inner-container,
  .video-container,
  .video-container video {
    width: 100%;
    height: 70vh;
  }
}

@keyframes calcErortextBackgroundAnimation {
  from {
    left: -10%;
    content: "";
  }
  to {
    left: 100%;
    content: "";
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
    pointer-events: none;
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
    pointer-events: none;
  }
}

@keyframes videoTextAnimation3 {
  0% {
    width: 10%;
    height: 2px;
    top: -2px;
    left: -10%;
    background-color: red;
  }
  10% {
    width: 110%;
    height: 2px;
    top: -2px;
    left: 0;
    background-color: red;
  }
  20% {
    width: 2px;
    height: 2px;
    top: -2px;
    left: 100%;
    background-color: red;
  }
  25% {
    width: 2px;
    height: 15%;
    top: -30%;
    left: 100%;
    background-color: red;
  }
  30% {
    width: 2px;
    height: 130%;
    top: 0;
    left: 100%;
    background-color: red;
  }
  40% {
    width: 2px;
    height: 2px;
    top: 100%;
    left: 100%;
    background-color: red;
  }
  45% {
    width: 10%;
    height: 2px;
    top: 100%;
    left: 110%;
    background-color: red;
  }
  50% {
    width: 110%;
    height: 2px;
    top: 100%;
    left: -10%;
    background-color: red;
  }
  60% {
    width: 2px;
    height: 2px;
    top: 100%;
    left: -2px;
    background-color: red;
  }
  70% {
    width: 2px;
    height: 15%;
    top: 130%;
    left: -2px;
    background-color: red;
  }
  75% {
    width: 2px;
    height: 120%;
    top: 0;
    left: -2px;
    background-color: red;
  }
  80% {
    width: 2px;
    height: 120%;
    top: -30%;
    left: -2px;
    background-color: red;
  }
  90% {
    width: 2px;
    height: 2px;
    top: -2px;
    left: -2px;
    background-color: red;
  }
  100% {
    width: 5%;
    height: 2px;
    top: -2px;
    left: -10%;
    background-color: red;
  }
}

@keyframes videoTextAnimation2 {
  0% {
    width: 0%;
    height: 2px;
    top: -2px;
    left: -10%;
    background-color: red;
  }
  10% {
    width: 110%;
    height: 2px;
    top: -2px;
    left: 0;
    background-color: red;
  }
  20% {
    width: 2px;
    height: 2px;
    top: -2px;
    left: 100%;
    background-color: red;
  }
  25% {
    width: 2px;
    height: 2px;
    top: -30%;
    left: 100%;
    background-color: red;
  }
  30% {
    width: 2px;
    height: 130%;
    top: 0;
    left: 100%;
    background-color: red;
  }
  40% {
    width: 2px;
    height: 2px;
    top: 100%;
    left: 100%;
    background-color: red;
  }
  45% {
    width: 2px;
    height: 2px;
    top: 100%;
    left: 110%;
    background-color: red;
  }
  50% {
    width: 110%;
    height: 2px;
    top: 100%;
    left: -10%;
    background-color: red;
  }
  60% {
    width: 2px;
    height: 2px;
    top: 100%;
    left: -2px;
    background-color: red;
  }
  70% {
    width: 2px;
    height: 2px;
    top: 130%;
    left: -2px;
    background-color: red;
  }
  75% {
    width: 2px;
    height: 120%;
    top: 0;
    left: -2px;
    background-color: red;
  }
  80% {
    width: 2px;
    height: 120%;
    top: -30%;
    left: -2px;
    background-color: red;
  }
  90% {
    width: 2px;
    height: 2px;
    top: -2px;
    left: -2px;
    background-color: red;
  }
  100% {
    width: 2px;
    height: 2px;
    top: -2px;
    left: -10%;
    background-color: red;
  }
}

@keyframes videoTextAnimation1 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes videoTextAnimation {
  0% {
    width: 50%;
    height: 2px;
    top: -2px;
    left: -2px;
    background-image: linear-gradient(
      to right,
      rgb(242, 199, 199),
      rgba(24, 24, 1, 0.015)
    );
  }
  5% {
    /* width: 50%; */
  }
  25% {
    width: 2px;
    height: 2px;
    top: -2px;
    left: 100%;
    background-image: linear-gradient(
      to bottom,
      rgb(242, 199, 199),
      rgba(24, 24, 1, 0.015)
    );
  }
  30% {
    height: 60%;
    width: 2px;
  }
  50% {
    width: 2px;
    height: 2px;
    top: calc(100%);
    left: 100%;
  }
  60% {
    left: 50%;
    width: 50%;
    height: 2px;
    background-image: linear-gradient(
      to left,
      rgb(242, 199, 199),
      rgba(24, 24, 1, 0.015)
    );
  }
  75% {
    width: 2px;
    height: 2px;
    top: calc(100%);
    left: -2px;
  }
  85% {
    width: 2px;
    height: 60%;
    top: 40%;
    background-image: linear-gradient(
      to top,
      rgb(242, 199, 199),
      rgba(24, 24, 1, 0.015)
    );
  }
  100% {
    width: 2px;
    height: 2px;
    top: 0px;
    left: -2px;
    background-image: linear-gradient(
      to top,
      rgb(242, 199, 199),
      rgba(24, 24, 1, 0.015)
    );
  }
}

.calculator-input {
  border: 1px solid rgb(192, 192, 192) !important;
}

.table {
  margin-bottom: 0px !important;
}

/* fb chat style */
/* styles.css */

/* Adjust the position of the chat plugin */
.fb-customerchat {
  height: 100px;
  width: 200px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000; /* Ensure it's above other elements */
  background-color: #e1306c;
}

/* Style the chat icon */
.fb-customerchat iframe {
  width: 300px; /* Adjust width as needed */
  height: 100px; /* Adjust height as needed */
  border: none; /* Remove border */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for visual effect */
  border-radius: 30px; /* Optional: Rounded corners */
}

/* Style the chat icon when hovered */
.fb-customerchat iframe:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* Example hover effect */
  transform: scale(1.05); /* Example hover effect */
}
